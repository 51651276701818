import crederaLogo from "./../images/crederaLogo.png";
import UoNLogo from "./../images/UoN.jpeg";
import codexLogo from "./../images/codexLogo.png";
import ktlLogo from "./../images/ktlLogo.png";

function ExpData() {
  const credera = [
    {
      description:
        "Developed a full-stack SPA website for an internal social media platform, using React JS, GCP, Springboot framework, IaC (terraform) and GitHub actions (CI/CD).",
      key: 0,
    },
    {
      description:
        "Implemented code to automate company's carbon auditing using Python, AWS Lambda, Pandas, Jupyter Notebook.",
      key: 1,
    },
    {
      description:
      "Integrated Kimble and Hubspot using AWS, Terrform and python. To help optimise the company's sales platform.",
      key: 2,
    },
    
    {
      description:
      "Developed a Retrieval-Augmented Generation (RAG) using Langchain, AzureOpenAI, Chainlit to query complex documents to optimise the company's Legal Team.",
      key: 3,
    },
    {
      description:
      "Financial Banking Group (client work) - Lead a team to develop two critical security dashboard using React, Typescript, Redux (RTK Query) and deployed to GCP using IaC . This helped the team replace Excel sheets and have one source of truth to raise and resolve vulnerabilities.",
      key: 4,
    },

    {
      description:
      "Financial Banking Group (client work) - Conducted Web Skills learning sessions for a team of 30 members. To upskills the banks Javascript knowledge.",
      key: 5,
    },

    {
      description:
      "Financial Banking Group (client work) - Implemented to Firebase App Monitoring to get performance metrics to help monitor their websites.",
      key: 6,
    },
  ];

  const ktl = [
    {
      description:
        "Developed web based UI for predictive maintainence (forging machines).",
      key: 0,
    },
    {
      description:
        "Analyzed & computed vibrations using fast fourier transform to generate machine learning models.",
      key: 1,
    },
    { description: "Designed UX using design principles and HCI.", key: 2 },
    {
      description: "Assisted in integration of the software at the shop floor.",
      key: 3,
    },
  ];

  const codex = [
    { description: "Formatting and conceptualising Python courses.", key: 0 },
    { description: "Marketing and Advertisement of the same.", key: 1 },
  ];

  const UoN = [
    {
      description:
        "Conducted quizzes related to Computer Science & Induction sessions for First Year students.",
      key: 0,
    },
    {
      description: "Guided students through the course & assignments.",
      key: 1,
    },
  ];

  const expContent = [
    {
      title: "Software Engineer",
      company: "Credera UK, Manchester UK",
      achievements: credera,
      time: "10/2022 - Pres",
      companyLogo: crederaLogo,
    },
    {
      title: "Associate Front End Developer at R&D Department",
      company: "Kalyani Technologies Ltd, Pune, India",
      achievements: ktl,
      time: "04/2021 - 08/2021",
      companyLogo: ktlLogo,
    },
    {
      title: "Technical Specialist",
      company: "CodeX, Nottingham, UK",
      achievements: codex,
      time: "01/2020 - 04/2020",
      companyLogo: codexLogo,
    },
    {
      title: "Mentor, Computer Science Department",
      company: "University of Nottingham, Nottingham, UK",
      achievements: UoN,
      time: "08/2020 - 12/2020",
      companyLogo: UoNLogo,
    },
  ];

  return expContent;
}

export default ExpData;
