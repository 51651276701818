function SkillData() {
  const skills = [
    "GCP",
    "AWS",
    "Terraform",
    "CI/CD",
    "SQL",
    "Firebase Monitoring",
    "Node JS",
    "Duck DB",
    "Docker",
    "React JS",
    "JavaScript",
    "Typscript",
    "Redux/RTK Queries",
    "Material UI",
    "SpringBoot",
    "Android Studio",
    "MATLAB",
    "CSS",
    "HTML5",
    "Python",
    "JAVA",
    "Ollama",
    "Langchain",
    "Llamma Index",
    "Azure Open AI",
    "Chainlit"
  ];

  return skills;
}

export default SkillData;
