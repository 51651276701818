import "./Achievements.css";

function Achievements({ title, time, description }) {
  return (
    <div className="flex mb-6">
      <div className="basis-full">
        <div className="flexflex-col">
          <div className="flex-initial mr-3 text-left">
            <div className="ml-4">
              <strong>{title}</strong>
            </div>
          </div>

          <div className="flex-initial text-left">
            <div className="ml-4">{time}</div>
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex-initial mr-3">
            {description != undefined && (
              <ul className="list-disc ml-12 text-left">
                <li>{description}</li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Achievements;
