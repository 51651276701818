function AchievementData() {
  const achievements = [
    {
      title: "Custom Interactive ReactJs Map ",
      time: "(06/2023 - Pres)",
      description:
        "Designed and developed an interactive responsive detailed plot map for a real estate client. Used Figma for design. Typescript, ReactJs, Material UI and 'react-zoom-pan-pinch' library to develop the map. Using python to PyMuPDF library to automate additions of maps.",
    },
    {
      title: "CSR Fundraising Manager",
      time: "(01/2023 - Pres)",
      description:
        "Point of contact between charities and Credera UK for Manchester Office.",
    },
    {
      title: "Sqaush Club Lead",
      time: "(12/2022 - Pres)",
      description:
        "Took leadership of organising a monthly squash social club, to help employees bond outside work environment",
    },
    {
      title: "Chat Bot",
      time: "(11/2021 - 01/2022)",
      description:
        "Developed a chat bot to display flight details and answer general knowledge questions in python using NLTK",
    },
    {
      title: "Running Tracker Application (Android)",
      time: "(11/2021 - 01/2022)",
      description:
        "Developed a location tracking fitness application using android studios as part of my coursework at university",
    },
    {
      title: "Course Rep for Computer Science & AI",
      time: "(09/2021 - 03/2022)",
      description: "Intermediate between faculty and peers.",
    },
    {
      title: "New Youth Association",
      time: "(04/2019 - 03/2020)",
      description:
        "Organized a Plogging Event (cleanliness drive), youth awareness seminar & a food drive (COVID 19 relief).",
    },
    {
      title: "Food Factory Staffline (Part-time)",
      time: "(05/2022 - 06/2022)",
      description:
        "Worked a part-time job in my 3rd year of college to help with my expenses.",
    },
  ];
  return achievements;
}

export default AchievementData;
