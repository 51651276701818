import uniLogo from "./../images/UoNLogo.png";
import profilePicture from "./../images/profilePicture.jpg";
import crederaLogo from "./../images/crederaLogo.png";


function AboutMeData() {
  const university = "University of Nottingham";
  const degree = "BSc Computer Science and AI with Hons";
  const dob = "2001-05-23";
  const location = "Manchester, UK";

  const aboutMeData = {
    university: university,
    degree: degree,
    dob: dob,
    location: location,
    uniLogo: uniLogo,
    crederaLogo: crederaLogo,
    profilePicture: profilePicture,
  };
  return aboutMeData;
}

export default AboutMeData;
