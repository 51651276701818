function CertData() {
  const certifications = [
    {
      title: "Associate Cloud Engineer Certification - Google Cloud",
      time: "(01/2023 - Pres)",
    },
    {
      title: "AWS Cloud Practitioner",
      time: "(01/2023 - Pres)",
    },
    {
      title: "Terraform Associate 003",
      time: "(02/2024 - Pres)",
    },
    {
      title: "University of Michigan – 5 python courses",
      time: "(01/2020 - Pres)",
    },
  ];
  return certifications;
}

export default CertData;
