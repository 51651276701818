import "./AboutMe.css";
import { useState, useEffect } from "react";

function AboutMe({
  university,
  degree,
  location,
  uniLogo,
  crederaLogo,
  dob,
  profilePicture,
}) {
  const [currentAge, setCurrentAge] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      let born = new Date(`${dob}`);
      let now = new Date();

      let years = Math.floor((now - born) / (1000 * 60 * 60 * 24 * 365));

      setCurrentAge(years);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div className="aboutme my-3 p-3 rounded-lg">
      <div className="flex md:flex-row flex-col">
        <div className="md:basis-1/4 basis-full self-center">
          <div className="text-center p-1 aboutme__content__bg rounded-full m-1">
            <img
              className="w-full max-w-xs p-1 rounded-full"
              src={profilePicture}
              alt=""
            />
          </div>
        </div>
        <div className="md:basis-3/4 basis-full self-start">
          <div className="p-5 aboutme__content__bg rounded m-1 ">
            <div className="flex flex-row">
              <div className="basis-full">
                <p className="text-left aboutme__header__bg p-2 rounded mb-3">
                  <strong>Current Employer:</strong>
                </p>
              </div>
            </div>

            

            <div className="flex justify-between">
              <div className="basis-3/4">
                <p className="text-left p-1">
                  <strong>Company</strong> - Credera UK <br />
                  <br />
                  <strong>Position</strong> - Software Engineer
                </p>
              </div>

              <div className="basis-1/4 p-1">
                <img className="max-w-[50px]" src={crederaLogo} alt="UoN Logo"/>
              </div>
            </div>
          </div>


          <div className="p-5 aboutme__content__bg rounded m-1 ">
            <div className="flex flex-row">
              <div className="basis-full">
                <p className="text-left aboutme__header__bg p-2 rounded mb-3">
                  <strong>Undergraduate:</strong>
                </p>
              </div>
            </div>

            

            <div className="flex items-center">
              <div className="basis-3/4">
                <p className="text-left p-1">
                  <strong>Institution</strong> - {university} <br />
                  <br />
                  <strong>Degree</strong> - {degree}
                </p>
              </div>

              <div className="basis-1/4 p-1 self-start">
                <img className="w-96" src={uniLogo} alt="UoN Logo" />
              </div>
            </div>
          </div>
          <div className="p-5 aboutme__content__bg rounded m-1 mt-2">
            <p className="text-left p-1">
              <strong>Location</strong> - {location}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
