import "./App.css";
import AboutMe from "./components/AboutMe/AboutMe";
import Achievements from "./components/Achievements/Achievements";
import Experience from "./components/Experience/Experience";
import Header from "./components/Header/Header.js";
import Navbar from "./components/Navbar/Navbar";
import { handleClickScroll } from "./components/Navbar/Navbar";

import AboutMeData from "./data/AboutMeData";
import AchievementData from "./data/AchievementData";
import CertData from "./data/CertData";
import InterestsData from "./data/InterestsData";
import SkillData from "./data/SkillData";
import LanguageData from "./data/LanguageData";
import ExpData from "./data/ExpData";

import { FaLinkedin, FaPhoneAlt, FaGithub } from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";
import { BiUpArrowCircle } from "react-icons/bi";

function App() {
  const aboutMe = AboutMeData();
  const achievements = AchievementData();
  const certifications = CertData();
  const interests = InterestsData();
  const skills = SkillData();
  const languages = LanguageData();
  const expContent = ExpData();

  return (
    <div id={"#Top"} className="App font-mono">
      <div className="flex flex-row">
        <div className="basis-3/4 pl-8 pt-4">
          <Header />
        </div>

        <div className="basis-1/4 pr-6 pt-6 ">
          <Navbar />
        </div>
      </div>

      <div className="h-full w-full">
        <div className="m-5 p-3">
          <div id="#AboutMe" className="font-semibold text-xl">
            About Me
          </div>
          <hr className="w-full mt-1 border-1 border-black"></hr>
          <AboutMe
            university={aboutMe.university}
            degree={aboutMe.degree}
            location={aboutMe.location}
            uniLogo={aboutMe.uniLogo}
            crederaLogo={aboutMe.crederaLogo}
            dob={aboutMe.dob}
            profilePicture={aboutMe.profilePicture}
          />
        </div>
      </div>



      <div>
        <div className="m-5 p-3">
          <div id="#Experience" className="font-semibold text-xl">
            Experience
          </div>
          <hr className="w-full mt-1 border-1 border-black"></hr>

          <div className="flex flex-wrap justify-center experience__container">
            {expContent?.map((exp, index) => {
              return (
                <Experience
                  title={exp.title}
                  company={exp.company}
                  achievements={exp.achievements}
                  time={exp.time}
                  companyLogo={exp.companyLogo}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div>
        <div className="m-5 p-3">
          <div id="#Achievements" className="font-semibold text-xl">
            Achievements
          </div>
          <hr className="w-full mt-1 border-1 border-black mb-3"></hr>

          {achievements?.map((achievement, index) => {
            return (
              <Achievements
                title={achievement.title}
                time={achievement.time}
                description={achievement.description}
              />
            );
          })}
        </div>
      </div>

      <div>
        <div className="m-5 p-3">
          <div id="#CertificateSkills" className="font-semibold text-xl">
            Certificate and Skills
          </div>
          <hr className="w-full mt-1 border-1 border-black mb-3"></hr>

          <div className="flex flex-col md:flex-row">
            <div className="basis-1/2 mb-8">
              {certifications?.map((certification, index) => {
                return (
                  <Achievements
                    title={certification.title}
                    time={certification.time}
                  />
                );
              })}
            </div>

            <div className="basis-1/2">
              <div className="flex items-center flex-wrap">
                {skills?.map((skill, index) => {
                  return (
                    <div className=" m-1 p-3 rounded-lg bg-orange-200	">
                      {skill}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="m-5 p-3">
          <div id="#InterestsLanguages" className="font-semibold text-xl">
            Interests and Languages
          </div>
          <hr className="w-full mt-1 border-1 border-black mb-3"></hr>

          <div className="flex flex-col md:flex-row">
            <div className="basis-1/2 mb-8">
              <div className="flex items-center flex-wrap">
                {interests?.map((interest, index) => {
                  return (
                    <div className=" m-1 p-3 rounded-lg bg-orange-200	">
                      {interest}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="basis-1/2">
              <div className="flex items-center flex-wrap">
                {languages?.map((language, index) => {
                  return (
                    <div className=" m-1 p-3 rounded-lg bg-orange-200	">
                      {language}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="m-5 p-3">
          <div id="#Contact" className="font-semibold text-xl">
            Contact
          </div>
          <hr className="w-full mt-1 border-1 border-black mb-3"></hr>

          <div className="flex  md:flex-row">
            <div className="basis-full mb-8">
              <div className="flex justify-between items-center p-5 flex-wrap">
                <a
                  href="https://github.com/Atharva1423"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub size={35} />
                </a>
                <a
                  href="https://uk.linkedin.com/in/atharv-hattarki-1935a01b0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin size={35} />
                </a>
                <a href="tel:+91 9527726033" target="_blank" rel="noreferrer">
                  <FaPhoneAlt size={35} />
                </a>
                <a
                  href="mailto: hattarki74@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiFillMail size={35} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        className="sticky bottom-0 float-right p-5"
        onClick={() => handleClickScroll("#Top")}
      >
        <BiUpArrowCircle size={35} />
      </button>
    </div>
  );
}

export default App;
