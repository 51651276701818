import "./Experience.css";

function Experience({ title, company, achievements, time, companyLogo }) {
  return (
    <div className="experience my-5 p-5 rounded-lg basis-full">
      <div className="flex items-center">
        <div className="basis-full">
          <div className=" text-left aboutme__header__bg p-2 rounded mb-3">
            {title}
          </div>

          <div className="flex items-center justify-between">
            <div className="flex-initial text-left mr-3 ">
              <div className="ml-4">
                <strong>Company</strong> - {company}
              </div>
            </div>

            <div className="flex-initial max-w-[50px]">
              <img src={companyLogo} alt="companyLogo" />
            </div>
          </div>

          <div className="flex items-center">
            <div className="flex-initial text-left ml-4 mt-2">
              <ul>
                <strong>Achievements:</strong>
                {achievements?.map((achievement, index) => {
                  return (
                    <li className="ml-5 mb-3" key={achievement.key}>
                      {achievement.description}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="flex items-center">
            <div className="flex-initial text-left ml-4 mt-2">
              <strong>Time</strong> - {time}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;
