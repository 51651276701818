function InterestsData() {
  const interests = [
    "High Altitude Mountaineering",
    "Filmmaking",
    "Long Distance Cycling",
    "Social Work & Community Service",
    "Audio & Video editing",
    "Archery",
    "Boxing",
    "Wall Climbing",
    "Basketball",
    "Squash",
    "Soccer",
    "Swimming",
  ];
  return interests;
}

export default InterestsData;
