function Header() {
  return (
    <div className="text-left">
      <div className="font-bold text-4xl">Atharva Hattarki</div>
      <div className="text-sm">
        Software Developer | Sustainability | Sports
      </div>
    </div>
  );
}

export default Header;
