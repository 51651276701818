import navbarIcon from "../../images/navbarIcon.png";
import React, { useState } from "react";
import "./Navbar.css";

function Navbar() {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const handleToggle = () => {
    setNavbarToggle(!navbarToggle);
  };

  // const handleClickScroll = (linkName) => {
  //   const element = document.getElementById(linkName);
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  return (
    <div className="">
      <div className="text-right">
        <button onClick={() => handleToggle()}>
          <img src={navbarIcon} alt="navbar " width="80px" />
        </button>
      </div>

      {navbarToggle && (
        <div className="text-right ">
          <ul
            id="#navbarList"
            className="navbar__list text-center mt-6 text-xl"
          >
            <button onClick={() => handleClickScroll("#AboutMe")}>
              <li>About Me</li>
            </button>
            <div className="px-6 py-3">
              <hr className="w-full " />
            </div>

            <button onClick={() => handleClickScroll("#Experience")}>
              <li>Work Experience</li>
            </button>
            <div className="px-6 py-3">
              <hr className="w-full " />
            </div>

            <button onClick={() => handleClickScroll("#Achievements")}>
              <li>Achievements</li>
            </button>
            <div className="px-6 py-3">
              <hr className="w-full " />
            </div>

            <button onClick={() => handleClickScroll("#CertificateSkills")}>
              <li>Certificate and Skills</li>
            </button>
            <div className="px-6 py-3">
              <hr className="w-full " />
            </div>

            <button onClick={() => handleClickScroll("#InterestsLanguages")}>
              <li>Interests and Languages</li>
            </button>
            <div className="px-6 py-3">
              <hr className="w-full " />
            </div>

            <button onClick={() => handleClickScroll("#Contact")}>
              <li>Contact</li>
            </button>
            <div className="px-6 py-3">
              <hr className="w-full " />
            </div>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Navbar;

export const handleClickScroll = (linkName) => {
  const element = document.getElementById(linkName);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};
